import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
// import {mapGoatsList} from '@core/utils/mapJson'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useGoatsList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'nama_ternak', sortable: true },
    { key: 'umur', sortable: true },
    { key: 'status_genetik', sortable: true },
    { key: 'jenis_kelamin', sortable: true },
    { key: 'berat', sortable: true },
    { key: 'kandang', sortable: true },
    { key: 'terakhir_diperbaruhi', sortable: true },
    { key: 'status_aktif', sortable: true },
    { key: 'aksi' },
  ]
  const perPage = ref(10)
  const totalGoats = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalGoats.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery,], () => {
    refetchData()
  })

  const mapGoatsList = ({
    id,
    name, 
    age, 
    genetic_status, 
    gender, 
    weight, 
    pen, 
    last_updated, 
    status,
    photo,
   }) => ({
    id,
    nama_ternak: name ?? 'N/A',
    umur: age ?? 'N/A',
    status_genetik: genetic_status ?? 'N/A',
    jenis_kelamin: gender ?? 'N/A',
    berat: weight ?? 'N/A',
    kandang: pen ?? 'N/A',
    terakhir_diperbaruhi: last_updated ?? 'N/A',
    status_aktif: status ?? 'N/A',
    photo,
  });

  const fetchGoats = (ctx, callback) => {
      store
      .dispatch('app-goat/fetchGoats')
      .then((response) => {
        const data = response.data.data
        const dataMapped = data.map(mapGoatsList)
        const { total } = response.data.data
        
        callback(dataMapped)
        totalGoats.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching goats list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })    
  }

   // UI
   const resolveGenderAnimalVariant = gender => {
    if (gender === 'Male') return 'info'
    if (gender === 'Female') return 'danger'
    return 'primary'
  }

  return {
    fetchGoats,
    tableColumns,
    perPage,
    currentPage,
    totalGoats,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,

    resolveGenderAnimalVariant,
  }
}
