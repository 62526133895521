import axios from '@axios'

export default {
  namespaced: true,
  state: {
    goats: null,
  },
  getters: {
    goats(state) {
      return state.goats
    }
  },
  mutations: {
    SET_GOATS(state, goats) {
      state.goats = goats
    }
  },
  actions: {
    fetchGoats({commit}, ctx, queryParams) {
      return new Promise((resolve, reject) => {
          axios
          .get('/animals')
          .then((response) => {
            resolve(response)
            commit('SET_GOATS', response)})
          .catch(error => reject(error))
      })
    },
    fetchGoat(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/animals/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addGoat(ctx, goatData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/animals', goatData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteGoat(ctx, goat) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/animals/${goat.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
