<template>
  <div>

    <goat-list-add-new
      :is-add-new-goat-sidebar-active.sync="isAddNewGoatSidebarActive"
      :goat-parents-options="goatsParentList"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

        <!-- Table Top -->
        <b-row class="pt-2 pl-2 pr-2">  
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h4>List Ternak</h4>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            
            <b-button
                variant="primary"
                @click="isAddNewGoatSidebarActive = true"
              >
                <span class="text-nowrap">+ Tambah Data</span>
              </b-button>
          </b-col>
        </b-row>
        <hr/>
        <b-row class="pl-2 pr-2 pb-2">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              
              
            </div>
          </b-col>
        </b-row>

      

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchGoats"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(nama_ternak)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.photo"
                :text="avatarText(data.item.nama_ternak)"
                :variant="`light-${resolveGenderAnimalVariant(data.item.jenis_kelamin)}`"
                :to="{ name: 'goatsDetail', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'goatsDetail', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.nama_ternak }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(aksi)="data">

          <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >
              <template #button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                  />
              </template>
              <b-dropdown-item
                  :to="{
                      name: 'goatsDetail',
                      params: { id: data.item.id },
                  }"
              >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50"
                      >Detail Pencatatan</span
                  >
              </b-dropdown-item>

              <!-- <b-dropdown-item>
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item> -->

              <b-dropdown-item @click="handleDelete(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
          </b-dropdown>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} - {{ dataMeta.to }} dari {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <!-- <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          > -->

            <!-- <b-pagination
              v-model="currentPage"
              :total-rows="totalGoats"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination> -->

          <!-- </b-col> -->

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useGoatsList from './useGoatsList'
import goatStoreModule from './goatStoreModule'
import GoatListAddNew from './GoatListAddNew.vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    GoatListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    // use Toast
    const toast = useToast()

    const GOAT_APP_STORE_MODULE_NAME = 'app-goat'

    // Register module
    if (!store.hasModule(GOAT_APP_STORE_MODULE_NAME)) store.registerModule(GOAT_APP_STORE_MODULE_NAME, goatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GOAT_APP_STORE_MODULE_NAME)) store.unregisterModule(GOAT_APP_STORE_MODULE_NAME)
    })

    const isAddNewGoatSidebarActive = ref(false)   

    const {
      fetchGoats,
      tableColumns,
      perPage,
      currentPage,
      totalGoats,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Extra Filters
      resolveGenderAnimalVariant,
    } = useGoatsList()

    const handleDelete = async (idGoat) => {
        await store.dispatch('app-goat/deleteGoat', {id: idGoat}).then((response) => {
            if(response.data.status == 500){
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Gagal menghapus data ternak',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                    },
                })
            }
        })

        refetchData();
        toast({
            component: ToastificationContent,
            props: {
                title: 'Berhasil menghapus data ternak',
                icon: 'CheckIcon',
                variant: 'success',
            },
        })
    } 


    return {
      // Sidebar
      isAddNewGoatSidebarActive,
      handleDelete,

      fetchGoats,
      tableColumns,
      perPage,
      currentPage,
      totalGoats,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      resolveGenderAnimalVariant,
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
